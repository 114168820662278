<template>
  <div class="infobox">
    <van-nav-bar
      title="积分规则"
      left-arrow
      fixed
      placeholder
      @click-left="onClickLeft"
    />
    <div v-html="center"></div>
  </div>
</template>
<style lang="scss" scoped="true">
::v-deep{
  .van-nav-bar{
    height: 86px;
    .van-nav-bar__content{
      line-height: 86px;
      height: 86px;
      .van-nav-bar__title{
        font-size: 36px;
        color: #212121;
      }
      .van-icon{
        font-size: 40px;
        color: #212121;
        right: 20px;
      }
    }
  }
}
.red {
  color: red;
}
.fb {
  font-weight: bold;
  font-size: 32px;
}
.titlebox {
  margin-top: 15px;
  font-weight: bold;
}
.infobox {
  line-height: 45px;
  font-size: 26px;
  margin: 30px 25px;
}
</style>
<script>
import * as api from "@/utils/api";
export default {
  data() {
    return {
      center: "",
    };
  },
  methods:{
    onClickLeft(){
      this.$router.back()
    }
  },
  async mounted() {
    const depositDescription = await  api.getDefaultExplain({title:'integralRule'})
    this.center = depositDescription[0].content
  }
};
</script>
